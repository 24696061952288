.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282A36;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(32, 32, 32);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* Set the VSCode Dracula theme background color */
  background-color: #282a36;
  color: #f8f8f2; /* Foreground color */
  font-family: 'Courier New', Courier, monospace; /* Code-like font */
  overflow-x: hidden;
  -ms-overflow-style: none;
}

#code-background {
  position: fixed;
  top: 50px; /* Small margin from the top */
  left: 10px; /* Small margin from the left */
  width: calc(100% - 100px); /* Width minus margins */
  max-height: calc(100% - 100px); /* Width minus margins */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  z-index: -1; /* Keep it behind the main content */
  scrollbar-width: none; /* Hide scrollbar */
}

.comment {
  color: #6272a4;
}

#code-background {
  display: flex;
}

.line-numbers {
  width: 40px; 
  text-align: right;
  padding-right: 10px;
  color: #6272a4;
}

.line-numbers,
.language-javascript code {
    vertical-align: baseline;
    line-height: 1.5; 
    font-size: 30px;
    margin-top: 0px;
    scrollbar-width: none; /* Hide scrollbar */
    -ms-overflow-style: none;
    padding-top: 0px;
}

pre.language-javascript {
  margin-top: 0px;
  padding-top: 0px;
  vertical-align: baseline;
  line-height: 1.5; 
  font-size: 30px;
}

.container {
  padding: 10vh; 
  display: flex;
  justify-content: center;
  align-items: center; /* center the content inside each block */
  scroll-snap-align: center;
}

.content-box {
  width: 50vw; /* Set width */
  min-height: 30vh; /* Set minimum height */
  padding: 20px; /* Padding around content */
  background-color: #191924ef; /* Dracula background color */
  border-radius: 10px; 
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); 
  overflow: hidden; /* Ensures content stays within the box */
}

::-webkit-scrollbar {
  width: 0;  /* For vertical scrollbars */
  height: 0; /* For horizontal scrollbars */
}

.scrolling-container {
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
}


